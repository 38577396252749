@import "../../variables.scss";

.cart {
  margin-left: 25px;
  min-width: 250px;
  margin-right: 20px;
  position: sticky;
  top: 0; // if you change top padding you need to also change from component cart height
  display: flex;
  flex-direction: column;
  padding-top: 57px;
  min-height: 450px;

  .cart-header {
    width: 100%;
    background-color: #696b78;
    color: "#FFFFFF";
    font-size: 14pt;
    border-bottom: 4px solid $pine-yellow;
    // border-bottom: 4px solid #f8ae1d;
    color: white;
    padding: 10px;
    border-radius: 4px 4px 0px 0px !important;

    .apply-coupon-label {
      width: 100%;
      display: flex;
      justify-content: space-between;
      opacity: 0.9;
      // width: 100%;
      // display: flex; 
      // background-color: #444;
      border-radius: 4px;
      // padding: 10px;
      font-size: 11pt !important;

      // transition: 0.3s linear;
      &:hover {
        opacity: 1;
      }

      input {
        // flex-grow: 1;
      }

      button {
        border: none;
        margin-left: 3px;
        padding: 2px 13px;
        text-align: center;
        border-radius: 2px;
        color: #fff;
        font-weight: 600;
        box-shadow: 0px 0px 3px #000;
        background-color: $pine-yellow;
      }
    }
  }

  .cart-body {
    background: #f5f5f5;
    text-align: center;
    overflow: auto;
    flex-grow: 1;
    padding: 0px 7px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;

    .empty-cart {
      flex-grow: 1;
      padding-top: 30px;

      .text-main {
        margin-top: 10px;
        font-size: 16pt;
        font-weight: 600;
        color: #555;
      }

      .text-sub {
        color: #999;
        font-size: 11pt;
        padding: 10px;
      }
    }

    .cart-item-list {
      flex-grow: 1;

      .cart-item-disabled {
        cursor: not-allowed;

        button {
          pointer-events: none;
        }
      }

      .cart-item-enabled {
        cursor: pointer;
      }

      .offerCartitem {
        border-radius: 3px;
        background-color: #dedede !important;
        border: 1px solid #eee;
        padding: 7px;
        margin: 4px 0;

        .cart-item-header {
          display: flex;

          img {
            height: 45px;
            width: 45px;
            border: 1px solid #eee;
            display: block;
            object-fit: cover;
          }

          .header-text {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            padding: 0px 0px 0px 5px;

            .text1 {
              font-size: 10.3pt;
            }

            .text2 {
              font-size: 13pt;
            }

            .textOffer {
              font-size: 11pt;
              font-weight: 700;
              text-transform: uppercase;
              display: flex;
              justify-content: space-between;

              .textOffer_span {
                font-weight: 200 !important;
                text-align: right !important;
                font-size: 11pt !important;
              }
            }

          }
        }

        .qty-price {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;

          .qty-counter {
            .inc-dec-counter {
              scale: 0.9;
              margin-left: -5px;
            }
          }

          .price-text {
            font-weight: 600;
          }
        }

        .toppings {
          margin-top: 10px;

          .toppings-collapse {
            .collapse-header {
              font-size: 11pt;
              text-align: left;
              display: flex;
              align-items: center;

              .collapse-title {
                flex-grow: 1;
                display: flex;
                justify-content: space-between;
              }
            }

            .collapse-body {
              padding-top: 10px;

              .topping-cart-item {
                border-bottom: 2px solid #eee;
                display: flex;
                justify-content: space-between;
                padding: 5px 0 0 0;

                .topping-name {
                  color: #999;
                  font-size: 10pt;
                }

                .topping-qty-counter {
                  scale: 0.8;
                  margin-right: -5px;
                }
              }
            }
          }
        }

        .delete-edit-btn {
          margin-top: 10px;
          display: flex;

          button {
            border: 0;
            background-color: #fff;
            padding: 0;
            width: 30px;
            height: 30px;
          }
        }
      }

      .cart-item {
        border-radius: 3px;
        background-color: #fff;
        border: 1px solid #eee;
        padding: 7px;
        margin: 4px 0;

        .cart-item-header {
          display: flex;

          img {
            height: 45px;
            width: 45px;
            border: 1px solid #eee;
            display: block;
            object-fit: cover;
          }

          .header-text {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            padding: 0px 0px 0px 5px;

            .textOffer {
              font-size: 11pt;
              font-weight: 700;
              text-transform: uppercase;
              display: flex;
              justify-content: space-between;
            }

            .text1 {
              font-size: 10.3pt;
            }

            .text2 {
              font-size: 13pt;
            }
          }
        }

        .qty-price {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;

          .qty-counter {
            .inc-dec-counter {
              scale: 0.9;
              margin-left: -5px;
            }
          }

          .price-text {
            font-weight: 600;
          }
        }

        .toppings {
          margin-top: 10px;

          .toppings-collapse {
            .collapse-header {
              font-size: 11pt;
              text-align: left;
              display: flex;
              align-items: center;

              .collapse-title {
                flex-grow: 1;
                display: flex;
                justify-content: space-between;
              }
            }

            .collapse-body {
              padding-top: 10px;

              .topping-cart-item {
                border-bottom: 2px solid #eee;
                display: flex;
                justify-content: space-between;
                padding: 5px 0 0 0;

                .topping-name {
                  color: #999;
                  font-size: 10pt;
                }

                .topping-qty-counter {
                  scale: 0.8;
                  margin-right: -5px;
                }
              }
            }
          }
        }

        .delete-edit-btn {
          margin-top: 10px;
          display: flex;

          button {
            border: 0;
            background-color: #fff;
            padding: 0;
            width: 30px;
            height: 30px;
          }
        }
      }

      .hnh-cart-item {
        border-radius: 3px;
        background-color: #fff;
        border: 1px solid #eee;
        padding: 7px;
        margin: 4px 0;

        .side {
          margin-top: 10px;
        }

        .cart-item-header {
          display: flex;

          img {
            height: 45px;
            width: 45px;
            border: 1px solid #eee;
            display: block;
            object-fit: cover;
          }

          .header-text {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            padding: 0px 0px 0px 5px;

            .text1 {
              font-size: 10.3pt;
            }

            .text2 {
              font-size: 13pt;
            }
          }
        }

        .qty-price {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;

          .qty-counter {
            .inc-dec-counter {
              scale: 0.9;
              margin-left: -5px;
            }
          }

          .price-text {
            font-weight: 600;
          }
        }

        .toppings {
          margin-top: 10px;

          .toppings-collapse {
            .collapse-header {
              font-size: 11pt;
              text-align: left;
              display: flex;
              align-items: center;

              .collapse-title {
                flex-grow: 1;
                display: flex;
                justify-content: space-between;
              }
            }

            .collapse-body {
              padding-top: 10px;

              .topping-cart-item {
                border-bottom: 2px solid #eee;
                display: flex;
                justify-content: space-between;
                padding: 5px 0 0 0;

                .topping-name {
                  color: #999;
                  font-size: 10pt;
                }

                .topping-qty-counter {
                  scale: 0.8;
                  margin-right: -5px;
                }
              }
            }
          }
        }

        .delete-edit-btn {
          margin-top: 10px;
          display: flex;

          button {
            border: 0;
            background-color: #fff;
            padding: 0;
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    .subtotal {
      box-shadow: 0px 0px 3px #777;
      border: 1px solid #999;
      border-radius: 3px;
      background-color: #fff;
      padding: 5px 10px;

      .subtotal-item {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .cart-footer {
    position: sticky;
    bottom: 0px;
    width: 100%;
    background-color: #444;
    color: #ffffff;
    text-align: center;
    padding: 10px;
    border-radius: 0px 0px 4px 4px !important;

    .next-back-btns {
      display: flex;
      justify-content: space-between;

      button {
        border: none;
        padding: 3px 5px;
        font-size: 12pt;
        text-align: center;
        border-radius: 2px;
        color: #fff;
        font-weight: 600;
        box-shadow: 0px 0px 3px #000;
      }

      .back-btn {
        background-color: rgb(119, 119, 119);
      }

      .next-btn {
        background-color: $pine-yellow;
      }
    }
  }
}

.offersCart {}

.offerCartitem {
  border-radius: 3px;
  background-color: gray !important;
  border: 1px solid #eee;
  padding: 7px;
  margin: 4px 0;
}

.removeBtn {
  width: 60px;
  height: 28px;
  font-size: 12px;
  background-color: #000;
  font-weight: 600;
  color: #fff;
  border: 0;
  border-radius: 3px;
  margin-right: 3px;

}

.swapBtn {
  width: 50px;
  height: 28px;
  font-size: 12px;
  background-color: $pine-yellow;
  // background-color: #fcaf17;
  font-weight: 600;
  color: #fff;
  border: 0;
  border-radius: 3px;

}

.swapBtnActive {
  width: 50px;
  height: 28px;
  font-size: 12px;
  background-color: #c29d54;
  font-weight: 600;
  color: #fff;
  border: 0;
  border-radius: 3px;

}