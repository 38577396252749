@import "../../variables.scss";
.navbar-nav .nav-item .nav-link {
  color: #130c0e;
}

.navbar-nav {
  @media only screen and (max-width: map-get($breakpoints, $key: md)) {
    margin-top: 100px;
  }
  .nav-item {
    color: $pine-black !important;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
  }

  .active {
    .nav-link {
      color: $pine-yellow !important;
    }
  }
}

.navbar-toggler {
  border-radius: 1000px;
  padding: 0px;
  height: 40px;
  width: 40px;
  font-size: 18pt;
  border: 3px solid $pine-black !important;
  .fa-bars {
    color: $pine-black !important;
  }
}

.user-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
  .user-btn {
    margin: 0px 0px 0px 10px !important;
    padding: 0px !important;
    border-radius: 100%;
    border: 0;
    height: 30px;
    background-color: $pine-black;
    outline: none;
    .fa-user-circle {
      font-size: 30px;
      padding: 0;
      margin: 0;
      color: $pine-yellow !important;
    }
  }
}

.navForMobile {
  display: none;
}
.check{
  display: none;
}


@media only screen and (max-width: 767px) {
  .check{
    display: block;
  }
  .navForMobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .navForMobile > button {
    outline: none;
  }
  .badgeMobile {
    font-size: 8pt;
    background-color: $pine-yellow;
    // background-color: #fcaf17;
    position: absolute;
    top: 28px;
    right: 9px;
    border-radius: 1000px;
  }
  .cartSize {
    font-size: 22px;
  }
  .searchCart-box {
    display: flex;
  }

  .cartBox {
    margin: auto 10px auto 10px;
  }
  .search-input-navbar {
    padding: 0px;
    border: 0;
    border-radius: 1000px;

    outline: 0;
    background-color: #e3e3e3;
    width: 100%;
    padding: 3px 3px 3px 25px;
  }
  .badge-light-Mobile {
  }

  .searchCrossIcon {
    position: relative;
    left: 19px;
  }
  .marginWithIcon {
    margin-top: 75px;
  }
  .marginWithouticon {
    margin-top: 10px;
  }
}


.outletInfo {
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  padding:  0.4rem 1.2rem;
  margin-right: 2rem;
  border: 0.5px solid #000000;
}

@media only screen and (max-width: 576px) {
  .signIn {
    margin-right: 2rem;
  }
}


