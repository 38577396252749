.modal-header{
    align-items: center;
    .close{
        vertical-align: middle;
        font-size: 25pt;
        padding: 0 !important;
        margin: 0 !important;
        border: none !important;
        outline: none !important;
    }
}