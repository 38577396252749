@import "../../variables.scss";

.orderPreference {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .paper-container {
    background-color: #fff;
    box-shadow: 0px 0px 7px #777;
    border-radius: 7px !important;
    margin-top: -30px;
    overflow: hidden;
    z-index: 99 !important;

    @media only screen and (max-width: map-get($breakpoints, $key: sm)) {
      margin-top: 0px;
      margin: 5px;
      width: 100%;
    }

    .paper-left {
      border-right: 2px solid #ddd;
      &:hover {
        background-color: $pine-yellow;
      }
    }
    .paper-right {
      img {
        height: 50px !important;
      }
      &:hover {
        background-color: $pine-yellow;
      }
    }
    .selectedOrderType  {
      background-color: $pine-yellow;
    }
    .paper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 7px 40px !important;
      cursor: pointer;
      transition: 0.2s ease-in;

      img {
        height: 55px;
        margin: 0 10px;
      }

      @media only screen and (max-width: 500px) {
        padding: 7px 5px !important;
        img {
          margin: 0 5px;
        }
      }

      .paper-text {
        line-height: 15px;
        display: flex;
        flex-direction: column;
        font-size: 11pt;
        padding-left: 7px;
        @media only screen and (max-width: 500px) {
          padding-left: 2px;
        }
        b {
          font-size: 13pt;
        }
      }
    }
  }

  @media only screen and (max-width: map-get($breakpoints, $key: xs)) {
  }
  @media only screen and (max-width: map-get($breakpoints, $key: sm)) {
    .paper-container {
      .paper {
        img {
          height: 35px !important;
        }
        .paper-text {
          font-size: 8pt;
          b {
            font-size: 8pt;
          }
        }
      }
    }
  }
  @media only screen and (max-width: map-get($breakpoints, $key: md)) {
  }
  @media only screen and (max-width: map-get($breakpoints, $key: lg)) {
  }
}

@media only screen and (max-width: 500px) {
  .orderPreference {
    margin-top: 10px !important;
    // margin-top: 30px !important;
  }
  .orderPreference .paper-container .paper {
    padding: 7px 29px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */




.changeAddress {

  box-shadow: 0px 1px 11px 0px #00000036;
  background-color:  #F1F1F1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .3rem  1rem;
    button {
      border: none;
    }
}

.changeAddress__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}



.outlet__changeBtn {
  font-family: Poppins;
font-size: 14px;
font-weight: 600;
line-height: 16.8px;
color: #368AB9;

}

.changeAddress__span {
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap; 
  width: 300px;  
}


@media only screen and (max-width: 500px) {
  .paper-container {
    display: flex !important;
    width: 300px  !important;
  }

  .paper  {
    width: 50%;
  }
}