@import "../../variables.scss";

.footer {
  background-color: #282828;
  color: #ddd;
  padding-top: 50px;

  .social-page-row {
    .social-icons {
      display: flex;
      justify-content: right;

      .social-btn {
        font-size: large;
        background-color: #d8d8d8;
        border: 1px solid #979797;
        border-radius: 5px;
        width: 35px;
        height: 35px;
        margin: 0px 5px;
      }
    }

    .pages {
      ul {
        list-style-type: none;
        padding-left: 15px;

        li {
          line-height: 35px;
        }
      }
    }
  }

  .menu-row {
    margin-top: 20px;
    width: 100%;

    ul {
      list-style-type: none;
      padding-left: 30px;
      display: inline-grid;
      row-gap: 10px;
      @media only screen and (min-width: map-get($breakpoints, $key: xs)) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media only screen and (min-width: map-get($breakpoints, $key: sm)) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media only screen and (min-width: map-get($breakpoints, $key: md)) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media only screen and (min-width: map-get($breakpoints, $key: lg)) {
        grid-template-columns: repeat(6, 1fr);
      }
      li {
        display: inline-block;
        margin-right: 40px;
      }
    }
  }

  .footer-of-footer {
    line-height: 50px;
    background-color: #000;
    padding-left: 10px;
    color: #ddd;
  }
}
.text-white {
  color: #ddd;
}

@media only screen and (max-width: map-get($breakpoints, $key: md)) {
  .footer-menu {
    width: 100%;
  }
  .social-page-row {
    width: 100%;
  }
  .pages {
    width: 50%;
  }
  .social-icons {
    width: 50%;
    display: flex;

    flex-direction: column;
    justify-content: space-evenly !important;
    text-align: end;
  }
}
