// $pine-yellow: #fcaf17;
$pine-yellow: var(--test-primary);
$outlet-secandory: var(--test-secondary);
$pine-black: #130c0e;
$pine-font-ArialMT:"ArialMT";
$pine-font-ArialMT-bold:"ArialMT";
$pine-grey-label:#94959F;
//screen break points
$breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);