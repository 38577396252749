.splash{
    background-color: rgba(245, 245, 245, 0.95);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: absolute;
    flex-direction: row;
    
    img{
        width: 70px;
    }

    .text{
        font-size: x-large;
        font-weight: 600;
    }

    .spinner{
        width: 3rem; 
        height: 3rem;
    }
}