@import "./variables.scss";
@font-face {
  font-family: "ArialMT";
  font-style: normal;
  font-weight: normal;
  src: local("ArialMT"), url("./assets/fonts/arialmt.woff") format("woff");
}
@font-face {
  font-family: "ArialMT";
  font-style: normal;
  font-weight: normal;
  src: local("ArialMT"), url("./assets/fonts/ARIALBOLDMT.woff") format("woff");
}
body {
  margin: 0;
  // height: 100%; 
  font-family: "ArialMT", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// global styles
.bg-yellow {
  background-color: $pine-yellow;
}
.bg-black {
  background-color: $pine-black;
}
.text-yellow {
  color: $pine-yellow !important;
}
.text-black {
  color: $pine-black;
}

.modal-content {
  background-color: #eee;
}
.modal-open {
  padding-right: 0px !important;
}

.cardInputs{
  padding: 12px 8px !important;
  border-color: #aab7c4 !important;
    border-radius: 0 !important;
    height: 55px;
}

.modal-content {
  width: 600px;
}

.modal-title {
  font-size: 20px;
  color: #000d2a;
  text-align: center;
  line-height: 50px;
}
.modal-body {
  background-color: rgba(152, 152, 152, 0.27);
  box-shadow: 0 0 26px 5px rgba(152, 152, 152, 0.27);
}
.modal-dialog {
  width: 600px;
}

.swal2-modal{
  background-color: #dedede !important;
}
.swal2-confirm{
  background-color: $pine-yellow !important;
  // background-color: #fcaf17 !important;
  outline-color: black !important;
  font-weight: 700 !important;
  
} 
.swal2-cancel{
  background-color:black !important;
  outline-color: black !important;
  font-weight: 700 !important;
}
.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: $pine-yellow;
  background-color: $pine-yellow;
  // border-color: #fcaf17;
  // background-color: #fcaf17;
}

@media (min-device-width: 425px) and (max-device-width: 768px) {
  .custom-dialog {
    width: 80vw !important;
  }
  .modal-content {
    width: 80vw;
  }
  .modal-dialog {
    width: 80vw !important;
  }
}

@media only screen and (max-width: 425px) {
  
  .modal-content {
    width: 95vw;

} 
}
