@import "../../variables.scss";


.modal-content {
  width: 600px;
//  background-color: rgba(255, 255, 255, 0.58);
}


.modal-title {
  font-size: 20px;
  color: #000d2a;
  text-align: center;
  line-height: 50px;
}
.modal-body {
  // background-color: rgba(152, 152, 152, 0.27);
  // box-shadow: 0 0 26px 5px rgba(152, 152, 152, 0.27);
}
.modal-dialog {
  width: 600px;
}
// Geolocation
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.geosuggest__item {
  font-size: 15px;
  font-weight: 100;
  cursor: pointer;
}

.geosuggest__item:hover {
  color: black;
}

.geosuggest-ui {
  width: 100%;
}

.removeIcon {
  position: absolute;
  top: 1.3rem;
  z-index: 20;
  display: flex;
  height: 1.1rem;
  width: 1.6rem;
  transform-origin: center;
  transform: translateY(-50%) translateX(-50%);
  align-items: center;
  justify-content: center;
  right: -0.3rem;
  border: none;
  outline: none !important;
  //  background-color: transparent;
  color: #9ca3af;
}

.searchIcon {
  position: absolute;
  top: 1.3rem;
  z-index: 20;
  display: flex;
  height: 2rem;
  width: 2rem;
  transform-origin: center;
  transform: translateY(-50%) translateX(-50%);
  align-items: center;
  justify-content: center;
  left: 1.5rem;
  border: none;
  background-color: transparent;
  color: #9ca3af;
  outline: none !important;
}

.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}

.geosuggest-ui .geosuggest__input-wrapper {
  overflow: hidden;
  position: relative;
  z-index: 15;
}

.not--dilverable {
  color: red;
  font-size: 2rem;
}
.dilverable {
  color: green;
  font-size: 2rem;
}

.geosuggest-ui .geosuggest__input {
  align-items: center;
  border-radius: 4px;
  border-width: 0;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  justify-content: flex-start;
  line-height: 1;
  outline-color: #000;
  outline: 1px;
  overflow: hidden;
  padding: 4px 40px 4px 40px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  z-index: 3;
  list-style: lower-alpha;
  background-color: #F1F1F1;
  ;
  ;
}
.street {
  outline-color: #000;
  border-width: 0;
  outline: 1px;
}
.street:focus {
  border: 2px solid;
}

.geosuggest-ui .geosuggest__input:focus {
  border-width: 2px;
}

.geosuggest-ui .geosuggest__input::-webkit-input-placeholder {
  color: rgb(107, 114, 128);
  font-size: 16px;
  letter-spacing: -0.01em;
}

.geosuggest-ui .geosuggest__input::placeholder {
  color: rgb(107, 114, 128);
  font-size: 16px;
  letter-spacing: -0.01em;
}

.search--area {
  // margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.button--search {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 7px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.button--search:hover {
  background-color: #45a049;
}

.geosuggest-ui .geosuggest__suggests-wrapper {
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
  width: calc(100% + 2px);
  z-index: 10;
}

.geosuggest-ui .geosuggest__suggests {
  background-color: rgb(255, 255, 255);
  border-color: rgb(229, 229, 229);
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-width: 0 1px 1px;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 0 8px -2px rgba(0, 0, 0, 0.28);
  display: block;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  padding: 3px 8px 4px 17px !important;
  top: 0;
  width: 100%;
  z-index: 10;
}

.geosuggest-ui .geosuggest__suggests.geosuggest__suggests--hidden {
  display: none;
}

.geosuggest-ui .geosuggest__item {
  border-bottom-width: 1px;
  border-color: rgb(229, 231, 235);
  list-style: none;
}

.geosuggest__item::before:hover {
  color: red;
  // background-color: red;
}

.geosuggest-ui .geosuggest__item::before {
  content: "";
  display: inline-block;
  height: 26px;
  margin-right: 6px;
  width: 19px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" id="Layer_1" x="0px" y="0px" enable-background="new 0 0 24 24" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" width="26" stroke-width="1.5"><circle cx="12" cy="9.6" r="3.4" fill="none" stroke-miterlimit="10"></circle><path fill="none" stroke-miterlimit="10" d="M12,2C8.4,2,4.3,4.7,4.3,9s3.2,8.9,7.7,13c4.5-4.1,7.7-8.7,7.7-13 S15.6,2,12,2z"></path></svg>')
    no-repeat center;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
  filter: grayscale(100%);
}

.geosuggest-ui .geosuggest__item:hover::before {
  filter: none;
}

.geosuggest-ui .geosuggest__item:first-of-type {
  border-top-width: 1px;
}

.geosuggest-ui .geosuggest__item:last-of-type {
  border-bottom-width: 0;
}

// Geolocation

.delivery-dialog {
  border-radius: 8px;
  border-radius: 8px;
  padding-left: 30px;
  padding-right: 30px;

  .nav-link {
    border: 0px;
    border-radius: 0.25rem;
  }
  .close-btn {
    float: right;
    width: 24px;
    height: 24px;

    cursor: pointer;
    font-weight: bold;
    margin-right: -10px;
  }
  .schedule {
    align-items: center;
    //height: 50px;
    border-radius: 8px;
    display: flex;
    background-color: #ffffff;
    font-size: "19px";

    .schedule-now {
      width: 50%;
      text-align: center;
      justify-content: center;
      height: 100%;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      //padding-top: 17px;
    }
    .schedule-now:hover {
      cursor: pointer;
    }
    .schedule-later {
      width: 50%;
      text-align: center;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      height: 100%;
      //padding-top: 17px;
    }
    .schedule-later:hover {
      cursor: pointer;
    }
    .activetab {
      background: $pine-yellow !important;
      // background: #fcaf18 !important;
    }
  }

  .mobile {
    display: none;
  }
  .dektop {
    display: block;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
  .postal-code {
    margin-top: -1px;
  }
}

@media (min-device-width: 425px) and (max-device-width: 768px) {
  .custom-dialog {
    width: 80vw !important;
  }
  .modal-content {
    width: 80vw;
  }
  .modal-dialog {
    width: 80vw !important;
  }
}

@media only screen and (max-width: 425px) {
  .pickupHedding {
    font-size: 20px !important;
  }
  .modal-content {
    width: 95vw;
  }
  .pickUpDate {
    display: block;
    margin-top: "10px";
  }
  .pickUpDate > div {
    max-width: 100%;
  }
  .pickupAdress {
    font-size: 14px;
  }

  .pickupBtn {
    margin-bottom: 20px !important;
    margin-top: 22px !important;
  }

  .deliverDetail {
    display: block;
  }
  .deliverDetail > div {
    max-width: 100%;
  }

  .streetBlock {
    margin-bottom: 10px;
  }
  .deliverAddress {
    line-height: 8px !important;
  }

  .grid-container {
    grid-row-gap: 0px !important;
    grid-template-columns: none !important;
  }
  .mobile {
    display: block !important;
  }
  .desktop {
    display: none !important;
  }
  .mobile > span > img {
    width: 21px;
    margin-right: 10px;
  }
}

.pickUpDate {
  padding: 0px 20px;
}

.orderTypeCard {
  box-shadow: 0px 0px 21.7px 0px #0000001a;
  background-color: rgba(255, 255, 255, 0.58);
  padding: 1rem 1rem;
  border-radius: 12px;
}



@media only screen and (max-width: 576px) {
  .orderTypeMobile {

    width: 40vh;
    left: -48%;
    position: absolute;
    z-index: 999;
  }
}
.pickup-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
  column-gap: 18px;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 0px 20px;
  // padding: 20px;
  font-family: Arial, sans-serif;
  max-height: 200px;
  overflow: auto;
}

h2 {
  margin-bottom: 20px;
}

.pickup-option {
  width: 47%;
  display: flex;
  align-items: center;
  // padding: 15px;
  position: relative;
  height: 55px;
  margin-bottom: 7px;
  background-color: $outlet-secandory;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.pickup-option:hover {
  background-color: $outlet-secandory;
  transform: translateY(-2px);
}

.pickup-option.selected {
  background-color: $outlet-secandory;
  transform: translateY(-2px);
}

.pickup-option.disabled {
  background-color: #ccc; 
  cursor: not-allowed; 
}

.pickup-option.selected.disabled {
  background-color: $outlet-secandory;
  transform: translateY(-2px); 
}



.grey-block {
  width: 25%;
  height: 100%;
  background-color: grey;
  border-radius: 10px 0 0 10px;
  margin-right: 10px;
}

.grey-block.selected {
  background-color:  $pine-yellow
}

.pickup-option:not(.disabled):hover .grey-block {
  background-color:  $pine-yellow;
}

.grey-block.disabled {
  background-color: grey;
  cursor: not-allowed; 
}

.grey-block.selected.disabled {
  background-color:  $pine-yellow;
  // background-color: #FCAF17;
}


.location-info {
  width: 75%;
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.location-name {
  font-weight: bold;
  font-size: 12px;
}

.location-address {
  font-size: 9px;
  color: #555;
}

.pickup-option.disabled:hover,
.pickup-option.disabled.pickup-option:hover {
  // background-color: #ccc; 
  transform: none; 
}





.location-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.location-list li {
  padding: 15px 20px;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.2s ease-in-out, transform 0.1s;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.location-list li:hover {
  background-color: #f5f5f5;
  transform: translateY(-2px);
}

.location-list li.selected {
  background-color: #fcaf18;
  color: #ffffff;
  transform: scale(1.05);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 576px) {
  .pickup-option {
  width: 236px;

  }
}