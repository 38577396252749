@import '../../variables.scss';
.page-title {
    font-family: $pine-font-ArialMT-bold;
    font-size: 30px;
    color: #292c40;
    text-align: center;
    margin-top: 30px;
  }
  .star-rating{
    align-content: center;
  }
  .react-rater-star {
    height: 20px;
  }
  .grey-text{
    font-family: $pine-font-ArialMT-bold;
  font-size: 20px;
  color: #94959F;
  text-align: left;
  line-height: 30px;
  }