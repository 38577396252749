@import "../../variables.scss";

.topbar {
  display: flex;
  justify-content: center;

  @media only screen and (max-width: map-get($breakpoints, $key: md)) {
    // margin-top: 100px;
  }

  margin-bottom: 20px;
  .top-panel {
    background-color: $pine-black;
    line-height: 30px;
    width: 103%;
    color: #fff;
    border-radius: 1000px;
    padding: 3px;
    z-index: 1;
    height: 35px;

    .col-search {
      padding: 0;
      width: unset;
    }
    .search-box {
      position: relative;
      z-index: 9999;
      .icons {
        position: relative;
        z-index: 9999 !important
                ;
        font-size: large;
        color: #444;

        &.icon-left {
          margin-left: 5px;
        }
        &.icon-right {
          position: absolute;
          right: 6px;
          top: 4px;
          cursor: pointer;
        }

        &:hover {
          color: $pine-yellow;
        }
      }
      .search-input {
        padding: 0px;
        border: 0;
        border-radius: 1000px;
        position: absolute;
        left: 0;
        outline: 0;
        background-color: #fdfdfd;
        width: 100%;
        padding-left: 25px;
      }
    }
  }
  .col-right {
    display: flex;
    align-items: center;
    justify-content: right;
    font-size: 17pt;
    .cart-icon {
      cursor: pointer;
      z-index: 999;
      .fa-shopping-bag {
        position: absolute;
        top: 2px;
        right: 3px;
        &:hover {
          color: $pine-yellow;
        }
      }
      .badge {
        font-size: 8pt;
        background-color: $pine-yellow;
        position: absolute;
        bottom: -5px;
        right: -5px;
        border-radius: 1000px;
      }
    }

    .order-pref {
      font-size: 10pt !important;
      margin-right: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .address-radio {
        margin-right: 10px;
        white-space: nowrap;
        span {
          margin-left: -5px;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
      }
      .pickup-radio {
        label {
          span {
            margin-left: -5px;
          }
        }
      }
    }
  }
  .logo {
    position: absolute;
    top:var(--logo-top-height);
    z-index: 999;
    right: calc(50% - var(--logo-top-right));
    // right: 50%;
    margin: auto;
    width: var(--logo-top-width);

    -webkit-filter: drop-shadow(0px 0px 5px #333);
    filter: drop-shadow(0px 0px 5px #333);
  }
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-color: $pine-yellow;
  border-radius: 1000px;
}
.logoMobile {
  display: none;
}

.dialoagCart {
  display: block;
}

@media only screen and (max-width: map-get($breakpoints, $key: md)) {
  .dialoagCart {
    display: none;
  }
  .col-search {
    width: 85% !important;
  }
  .col-right {
    width: 10% !important;
  }
  .col-right {
    display: none !important;
  }

  .top-panel {
    height: 13px;
  }
  .serachHideforMobile {
    display: none;
  }
  .logo {
    display: none;
  }

  .logoMobile {
    position: absolute;
    top: 11px;
    z-index: 999;
    display: block;
    margin: auto;
    width: 126px !important;

    right: unset !important;

    -webkit-filter: drop-shadow(0px 0px 5px #333);
    filter: drop-shadow(0px 0px 5px #333);
  }
}

.cart-icon2 {
  cursor: pointer;
  z-index: 999;
  .fa-shopping-bag {
    position: absolute;
    top: 2px;
    right: 3px;
    
  }
  .badge {
    font-size: 8pt;
    background-color: $pine-yellow;
    position: absolute;
    bottom: -5px;
    right: -5px;
    border-radius: 1000px;
  }
}