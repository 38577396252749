@import "../../variables.scss";
.block-button {
  background-color: black;
  color: white;
  // height: 45px;
  height: 40px;
  border-radius: 8px;
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid block;
  border-width: 0px !important;
}
.input {
  // background-color: transparent;
  background-color: white;
  width: 320px;
  height: 40px;
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: $pine-font-ArialMT;
}

input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.simple-select {
  border-radius: 2px;
  position: relative;
  border: 1px solid #ced4da;
  font-size: 14px;
  padding: 2px 5px 5px 2px;
  height: 35px;
  width: 200px;
  font-family: $pine-font-ArialMT;
}

.inc-dec-counter {
  border: 1px solid #ddd;
  display: flex;
  width: 100px;
  height: 100%;
  text-align: center;
  border-radius: 2px;
  button {
    font-size: 8pt;
    &:hover {
      background-color: #eee;
    }
  }
  .box {
    flex-grow: 1;
    padding: 2px !important;
    background-color: #fff;
    border: 0;
    align-items: center;
    vertical-align: middle;
    &.box-minus {
      border-right: 1px solid #ddd;
    }
    &.box-value {
      border-right: 1px solid #ddd;
      font-size: 11pt;
    }
  }
}
.yellow-btn {
  background-color: $pine-yellow;
  font-size: 16px;
  font-family: $pine-font-ArialMT-bold;
  color: #fff;
  border: 0;
  border-radius: 3px;
  line-height: 24px;
  text-align: center;
  padding: 8px 5px;
  width: 100%;
  // margin-top: 19px;
  // display: flex;
}

.form-check-input {
  background: #fffdfa;
  border: 1px solid #e0dddd;
  border-radius: 4px;
  border-radius: 4px;
}
.form-check-label {
  font-family: $pine-font-ArialMT;
  font-size: 14px;
  color: #130c0e;
}
.viewalloffers{
  color: $pine-yellow;
  // color: #fcaf17;
}
.viewalloffers:hover{
  color: $pine-yellow;
  // color: #fcaf17;
}
@media only screen and (max-width: 425px) {
  .simple-select {
    width: 100% !important;
  }
}
.reorder {
  background-color: $pine-yellow;
  font-size: 16px;
  font-family: $pine-font-ArialMT-bold;
  color: #fff;
  border: 0;
  border-radius: 3px;
  line-height: 24px;
  text-align: center;
  padding: 8px 5px;
}