@import "../../variables.scss";

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.layout-header {
  position: static;
}

.layout-content {
  flex-grow: 1;
}

.layout-footer {}

.layout-header {
  position: static;
}

.layout-heade-menu {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1000;
}

//HEREEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE
.swal2-styled.swal2-confirm {
  background-color: #fcaf18 !important;
  color: white !important;
  // height: 40px !important;
  // width: 45px !important;
  // padding: 0px !important;
  // margin-top: -10px !important;
  // border-color: #e4d50dd3 !important;
}

.swal2-title {
  font-size: 20px !important;
  color: black !important;
}

.swal2-icon .swal2-icon-content {
  font-size: 22px !important;
}

.swal2-html-container {
  // color: black !important;
  // margin-top: -45px !important;
  // margin-left: 58px !important;
  // display: flex;
}

.swal2-icon.swal2-warning {
  color: #fcaf18 !important;
  border-color: #fcaf18 !important;
  height: 30px !important;
  width: 30px !important;
  border-width: 2px !important;
  padding-top: 0px !important;
  margin-top: 40px !important;
  // margin-left: 60px !important;
  margin-top: 30px !important;
}

.swal2-close {
  color: black !important;
  margin-top: 10px;
}



.swal-height {
  height: 160px !important;
  width: 390px !important;
  align-items: center !important;
  align-content: center !important;
}

// .iconClass{
//   padding-top: 500px !important;
// }