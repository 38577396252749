@import "../../variables.scss";
.custom-dialog {
  width: 600px;

  background: #eeeeee;
  box-shadow: 0 0 26px 5px rgba(152, 152, 152, 0.27);
  border-radius: 8px;
}
.signin-dialog {
  justify-content: center;
  border-radius: 8px;
  border-radius: 8px;
  align-items: center;
  padding: 0px 60px;
  height: 100%;
  width: 100%;
  font-family: "ArialMT";
}
.close-btn {
  float: right;
  width: 0px;
  height: 20px;
  margin-right: -10px;
  cursor: pointer;
  margin-top: 10px;
  font-weight: bold;
}
.small {
  font-size: 14px;
  color: #9b9b9b;
}
.signin-title {
  width: 100%;
  color: black;
  font-size: 32px;
  font-weight: bold;
}
.sign-in-link {
  // color: #fcaf17;
  color: $pine-yellow;
  cursor: pointer;
}
.signin-sub-title {
  font-size: 17px;
  color: #4a4a4a;
  text-align: center;
}
.social-box {
  display: flex;
  border-radius: 4px;
  border-radius: 4px;
  font-family: $pine-font-ArialMT;
  font-size: 16px;
  color: #f7f7f7;
  align-items: center;
  height: 41px;
  width: 100%;
  .social-icon {
    width: 35px;
    text-align: center;
    border-right: 1px solid #000;
    margin-right: 7px;
  }
  .social-text {
    flex-grow: 1;
    text-align: left;
    margin-left: 5px;
  }
}
.fb-section {
  background-image: linear-gradient(180deg, #3a579b 0%, #385698 100%);
  border: 1px solid #2d457b;
}
.gog-section {
  background-image: linear-gradient(180deg, #d45641 0%, #df4b32 100%);
  border: 1px solid #b23b27;
}

.div-row {
  display: flex;
  justify-content: space-between;
}
input[type="checkbox"] {
  background: #fffdfa;
  border: 0px solid #e0dddd !important;
  border-radius: 4px;
  border-radius: 4px;
  margin-right: 5px;
  accent-color:   $pine-yellow;
  // accent-color: #fcaf17;
}
.PrivateSwitchBase-root {
  padding: 0px !important;
}
.close-btn {
  float: right;
  width: 0px;
  height: 20px;
  right: 0px;
  cursor: pointer;
  margin-top: 6px;
  font-weight: bold;
  margin-right: 5px;
  background-color: rgba(152, 152, 152, 0.27);
}
.err-msg {
  color: red;
  font-size: 13px;
  margin-bottom: 5px;
}
.input-error {
  border-color: red;
}
.center-text {
  text-align: center;
}
.forgot-pass-section {
  cursor: pointer;
  color: $pine-yellow;
  // color: #fcaf17;
  font-size: 14px;
  font-family: $pine-font-ArialMT;
  text-align: right;
  font-weight: bold;
}
.forgot-pass {
  text-align: right;
}
.user-account-popover-title {
  background-image: linear-gradient(180deg, #fcd73e 0%, $pine-yellow 100%);
  // background-image: linear-gradient(180deg, #fcd73e 0%, #fcaf17 100%);
  border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0px 0px;
  height: 90px;
  //display: flex;
  align-items: center;

  .popover-header {
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    img {
    }
    .text {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .top-header {
    height: 100%;
    display: flex;
    align-items: center;
    img {
      margin: 0 15px;
    }
    .welcome-text {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      color: #130c0e;
      justify-content: center;
      .welcome-text-hello {
      }
      .welcome-text-name {
        font-size: large;
        font-weight: 600;
      }
    }
  }
}
.user-account-popover-content {
  background-color: #edf1f8;
  font-size: 15px !important;
  color: #696b78;
  letter-spacing: 0.6px;
  text-align: left;
  line-height: 35px;
  padding-top: 10px !important;
  .nav-link {
    display: inline;
  }
  .nav-link:hover{
    color: $pine-yellow;
    // color: #fcaf17;
  }

  
  .active {
    background-color: #ffffff;
    font-weight: bold;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}

.link-text {
  font-family: $pine-font-ArialMT-bold;
  font-size: 15px;
  color: #696b78;
  letter-spacing: 0.8px;
  text-align: left;
  line-height: 50px;
  white-space: nowrap;
}
.form-field-label {
  font-family: $pine-font-ArialMT;
  color: $pine-grey-label;
  text-align: left;
  margin: 14px 0;
  // margin-top: 24px !important;
}
.header-text {
  font-family: $pine-font-ArialMT-bold;
  font-size: 30px;
  color: #292c40;
  text-align: left;
}
.small-screen-user-account {
  font-family: $pine-font-ArialMT-bold;
  line-height: 50px;
  background-color: rgba(152, 152, 152, 0.27);
}


.facebookContainer > span > button{
 padding: 0px ;
 text-transform: inherit;
 font-family: ArialMT;
 font-weight: 100;
}
.kep-login-facebook{
  height: 41px !important;
  font-size: 15px !important;
  width: 100% !important;
  text-align: left;
  border-radius: 4px !important; 
} 
.fa{
  margin-right: 20px !important;
    font-size: 17px !important;
    // border-right: solid 1px rgb(44, 40, 40) !important;
    padding-right: 10px;
    height: 21px;
    padding-top: 2px;
    padding-left: 10px;
}
.googlecontainer > button{
  border: none !important;
  width: 100% !important;
  box-shadow: none !important;
}

.googlecontainer > button > div{
  display: none;
}
.googlecontainer > button >span{
  padding: 0 !important;
  width: 100% !important;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: map-get($breakpoints, $key: md)) {
  .custom-dialog {
    width: 90vw !important;
  }

  .forgot-pass {
    text-align: left;
  }
  .social-box {
    margin-top: 5px;
  }
  // .user-account-container {
  //   margin-top: 50px;
  // }
  .signin-dialog {
    padding: 10px;
  }
  .manAccInp {
    display: block;
  }

  .manAccInp > div {
    flex: 0;
    max-width: 100%;
  }
  .manageBtn {
    width: 100% !important;
    flex: unset !important;
    max-width: inherit !important;
  }
  .hideText {
    font-size: 12px;
  }
  .socialLogin {
    flex-wrap: inherit;
  }
  .singUpInps > input {
    height: 35px;
  }
  .accountHideInfo {
    display: block;
  }
  .accountInfo {
    display: none;
  }
  .hideText {
    line-height: 16px;
  }
  .forgetBtn > button {
    font-size: 18px;
    height: 60px;
  }
  .resetPasHedd{
    margin-top: 10px;
   

  }
}

.page-title {
  font-weight: 600;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .user-account-popover-content {
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .user-account-popover-content {
    font-size: 10px !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.user-account-popup {
  min-width: 275px;

  @media only screen and (max-width: map-get($breakpoints, $key: sm)) {
    min-width: 97%;
  }
}
