.containerOutlet {
    max-width: 100%;
    max-width: 100%;
    position: relative;
    background-color:#F6F6F6;
    ;
}


.imgRight {
    height:27rem ;
    width: 15rem;
    position: absolute;
    left: 0%;
    mix-blend-mode:multiply;
    top: 50%;
    transform: translate(0%, -50%); 

}
.imgLeft {
    height:27rem ;
    width: 15rem;
    position: absolute;
    right: 0%;
    top: 50%;
    transform: translate(0%, -50%); 
    mix-blend-mode:multiply;

}

.logo-container {
    width: 10rem; 
    height: 8rem; 
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent; 
    overflow: hidden; 
    position: relative;

  }
  
  .logoMain {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    position: absolute; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
  }

// .orderTypeContainer {
//     margin-top: 4rem;
// }

.outletHeader {
    font-family: Poppins;
font-size: 2rem;
font-weight: 600;
line-height: 40px;
text-align: center;
color: #885A00;

}

.outletMSG {
    font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 16.8px;
text-align: center;
color: #504F4F;
}

.outletOrderPref {
    margin-top: 34px;
}


.signIn__warapper {
    width: 100px;
    border: 1px solid;
}


@media only screen and (max-width: 576px) {
    .imgLeft {
        position: absolute;       
        transform: translate(0%, -50%); 
        mix-blend-mode:multiply;
        transform: rotate(90deg);
        bottom: 0;
        top: 70%;
        height: 26rem;
        width: 12rem;
        right: 112%;
    }
    .imgRight {
        display: none;
        height:19rem ;
        width: 9rem;
        position: absolute;
        left: 0%;
        mix-blend-mode:multiply;
        top: 50%;
        transform: translate(0%, -50%); 
        transform: rotate(90deg);
    }
    .logoMain{
        width: var(--logo-out-sel-height);
        height: var(--logo-out-sel-width);
    }
    .outletHeader {

    font-size: 1.5rem;
    line-height: 25px;
  
    
    }
    .containerOutlet {
        height: 110vh;
        overflow: scroll;
    }
    .outletOrderPref {
        margin-top: 0px;
    }
  }
