.order-placed{
    margin-top: 70px;
    text-align: center;
    .main-title{
        color: rgb(0, 156, 0);
        font-weight: 600;
        i{
            font-size: 40pt;
        }
    }
    .subtitle{
        color: #777;
    }
    .btns{
        .btn{
            color: #fff;
            width: 170px;
            font-weight: 600;
        }
    }
}
.ordertime{
    font-size: 16pt;
}